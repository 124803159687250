<template>
  <div class="container ageement commonWhiteBg">
    <h3>法律声明及隐私权政策</h3>
    <div class="subTime">最近更新日期：2022年11月23日 生效日期：2022年11月30日</div>
    <div>
      本协议是您（包括自然人、法人或其他组织，以下也称"服务使用人"）与中文出版物知识产权评价中心之间关于中国知链提供各项服务的协议。本协议中的"中国知链"是指由中文著作权评价中心运营的针对知识产权进行线上管理和保护的全环节、全维度的开放式系统平台。<br>

      我们在此特别提醒，在使用中国知链平台上提供的各项服务前，请您务必事先认真阅读本协议所有内容，协议中包括了免除或者限制中国知链责任的免责条款及对用户的权利限制。请您审阅并表示接受或不接受所有服务条款，若您承诺接受则须遵守本协议约定；若您不接受则应立即停止注册程序或停止使用中国知链提供的服务。若您有不明白或不理解之处，务必请向中国知链客服垂询，由客服人员向您作详细的说明和解释。<br>

      中国知链有权根据需要不时地制订、修改本协议及各种规范、规则等，并在中国知链平台上进行公告。变更后的协议和规范、规则一经公告，立即自动生效，您可以随时登录中国知链平台查阅相关信息。如果您不同意相关变更，应当立即停止使用中国知链服务。如您继续使用中国知链服务的，即视为您接受经修订的协议和规范、规则。<br>
    </div>
    <h4>一、产品保护条款</h4>
    <div>
      1.1 中国知链所提供的服务涉及的文字表述及组合、图片、网页设计等，以及各软件、技术均属中文著作权评价中心所有，受《中华人民共和国著作权法》等法律法规、国际著作权条约、国际版权条约和其他知识产权法及条约的保护。<br>

      1.2 您应正当使用中国知链的各项服务，并承担一定的意外风险。在使用中国知链服务过程中，您不得从事任何影响网络正常运作的行为，包括借助装置、软件或例行程序进行干预等。中国知链在任何情况下不对因服务使用人不正当使用、有意或恶意利用服务的缺陷或其他特殊的、意外的原因（或行为）造成的非直接或间接的损失承担赔偿责任。<br>

      1.3 由于中国知链的各项服务涉及到互联网服务、本地操作环境、网络各环节等因素的影响，因此服务可能因计算机病毒或黑客攻击、网络提供商提供的服务差异化或出现意外状况、用户所在位置以及其他任何技术、互联网络、通信线路等原因出现异常或其他不能满足用户要求的风险。对非中国知链造成的以上风险，中文著作权评价中心不承担任何责任。
    </div>
    <h4>二、新用户注册</h4>
    <div>
      2.1 您确认，在您进行中国知链注册或实际使用中国知链提供的服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，中国知链平台保留对您的帐号采取冻结使用乃至注销您的账户等权利。<br>

      2.2 涉及无民事行为能力人、限制民事行为能力人使用中国知链服务的情况，建议以其监护人的名义进行中国知链注册并获取中国知链提供的服务。<br>

      2.3 您在信息完善时所填写的身份信息不允许修改，除非您能提供充足的证据证明身份信息发生变更，但您无法以信息完善的信息对应的个体不存在为由，凭用户名及密码向中国知链要求更改信息完善的信息。因此，信息完善时请务必使用您本人的真实信息，并确保信息完善时所需信息填写准确、完全，使用虚假信息进行信息完善，有可能会影响您后续使用中国知链的其它功能、服务，甚至有可能损害您的权益。<br>

      2.4 信息完善是使用中国知链许多功能，如版权交易、司法通道等功能的前提。建议您在注册后尽快进行信息完善，可获得中国知链提供的更多服务项目。<br>

      2.5 您设置的用户名不应包含有非法的内容或其他可能侵犯他人合法权益、违背社会公序良俗的内容。一经发现，中国知链有权对你的帐户采取限制措施，并限期要求您进行修改，直至注销您的帐户。<br>

      2.6 中国知链发现、怀疑您登记的信息错误（包括有意或无意行为）、不实、过期或不完整的，中国知链有权要求您限期作出说明、提供相关证明材料更正登记的信息，有权对你的帐户采取限制措施，直至注销您的帐户。中国知链对此不承担任何责任，您将承担因此产生的任何直接或间接支出。<br>

      2.7 您必须认真阅读，在完全接受本协议的情况下，方可注册成中国知链的用户。一旦注册成为我们的用户，即表示您同意接受协议各项条款的约束，包括接受中国知链今后随时对服务条款所做的任何修改。届时您不应以未阅读本协议或不理解本协议的内容为由，主张协议无效，或要求撤销协议。<br>

      2.8 您在注册后，应牢记您的用户名及登录密码，妥善保管登录密码，采取积极的保密措施保护您（注册人）的上述信息及资料免受窃取及删除。用户名及登录密码将作为中国知链确认您的身份的依据，您应对您的用户名和密码的安全，以及通过您的用户名和密码实施的行为负责。<br>

      2.9 中国知链非经您本人（服务使用人）许可，不会将您的信息、相关数据、文件、作品泄露给他人。
    </div>
    <h4>
      三、中国知链平台服务使用规范
    </h4>
    <div>
      3.1 您必须合法地使用中国知链的各项服务，保证在使用中国知链的各项服务时所作陈述、记录等内容均真实、合法，您应当对通过您的帐户所实施的一切行为负责。<br>

      3.2 您不得利用中国知链的各项服务及产品从事非法活动，包括且不限于利用本站传播、存取非法、违反公序良俗的内容，不可采用网络、电脑技术等各种手段，制造虚假证据，并利用中国知链存证，以获取个人利益、骗取保全证书。<br>

      3.3 经发现或接到举报，或有合理理由怀疑您的帐户存在违法情况及其他违反本协议的行为时，中国知链有权对你的帐户采取限制措施，并限期要求您向中国知链公开可疑内容，限期进行整改，直至删除您的资料、应公安机关、司法机关的要求公开（公示）您的信息及违法内容、注销您的帐户。中国知链有权对您是否存在上述违法情况、违反本协议的行为单方作出认定，采取上述各项措施，无须征得您的同意或提前通知予您。<br>

      3.4 对于您的行为（包括非法的或正常使用中国知链服务的行为）对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保免于对中国知链造成损失或增加费用。<br>

      3.5 如中国知链因您的行为（包括非法的或正常使用中国知链服务的行为）遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿中国知链因此造成的损失及（或）发生的一切费用。<br>

      3.6 您如果发现或怀疑您的帐户被盗用，或存在其他安全问题时，您应当立即以有效方式通知中国知链，并可以要求中国知链采取一定的措施，且应配合中国知链为采取措施而实施的调查、要求补证、身份核实等工作。若您所提供的资料未能使中国知链完全确信您是帐户的注册人时，中国知链可能不会应您的要求采取措施。<br>

      3.7 中国知链不对您的帐户的安全问题承担任何责任。但您若冒用他人名义要求中国知链对帐户采取措施导致侵权、致他人损失的，您应承担一切责任。<br>

      3.8 因您填写、预留的联系方式有误，导致中国知链无法与您取得联系的，因此产生的后果由您自行负责，包括您使用服务过程中产生任何损失或增加费用等。<br>

      3.9 中国知链提供的各项服务均有一定的针对性，具体使用方式因服务内容不同而不尽相同。在使用前务必认真阅读各项服务的操作指南及须知，尤其是服务内容的限制，以免造成使用效果与您的设想存在过大的差距。<br>

      3.10 涉及交叉使用中国知链提供的各项服务的，应分别点击各服务项目进行。如有不清楚之处，务必联系客服人员，由客服人员对您进行指导。<br>

      3.11 在使用中国知链的各项功能服务之后，务必对相关的资料、结果进行浏览、查看、确认，以免发生意外，并有利于在发现意外，或因服务限制、错用服务等各项原因导致使用服务的效果不理想时及时采取补救措施。<br>

      3.12 一旦发生意外，非中国知链原因，收取的费用不予退还。在开始使用中国知链服务后，收费即开始。收费与使用功能服务的效果无关，您不得以效果不好达不到目的等理由，向中国知链提出退费要求。<br>

      3.13 中国知链提供的各项服务均为自助型服务，请勿向客服人员提出参与的要求。<br>

      3.14 为确保现场取证内容的效果，请您不要在嘈杂的环境中进行现场取证服务，并请现场无关人员回避。<br>

      3.15 确权、取证内容的真实性由您本人负责。 中国知链并不对您确权、取证内容的真实性进行审查，仅对您确权、取证的过程进行固定与证明。<br>

      3.16 非利用中国知链取证手段或在中国知链平台现场形成的证据资料，中国知链并不证明其形 成的环境和过程。
    </div>
    <h4>
      四、中国知链服务费用：以中国知链平台公布的价格为准。
    </h4>
    <h4>
      五、用户的变更
    </h4>
    <div>
      除非有法律规定或司法裁定，且征得中国知链的同意，否则，中国知链服务平台的用户名和密码不得以任何方式转让、赠与或继承（与帐户相关的财产权益除外）。
    </div>
    <h4>六、用户的终止与注销</h4>
    <div>
      6.1 出现以下情况时，中国知链有权直接以注销用户的方式终止本协议:

      <p>
        （1）您不是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织；<br>

        （2）您信息完善中的信息主要内容不真实、不准确、不及时、不完整，或注册信息包含非法的、可能侵犯他人合法权益的、违背社会公序良俗的内容，并经中国知链提示后未在限期内进行修改的；<br>

        （3）发现您利用中国知链从事违法的、侵犯他人合法权益、违背社会公序良俗的行为，且经中国知链提示后未在限期内改正的；<br>

        （4）本协议（含规则）变更时，您明示并通知中国知链不愿接受新的服务协议的；<br>

        （5）其它中国知链认为应当终止服务的情况。<br>
      </p>

      6.2 您与中国知链的协议关系终止后，您在使用中国知链服务平台期间存在违法行为或违反本协议或规则的行为的，中国知链仍可依据本协议向您要求承担法律责任。<br>
    </div>
    <h4>七、不可抗力、免责及责任限制</h4>
    <div>
      7.1 免责条款<br>

      因下列原因导致中国知链无法正常提供服务，中国知链免于承担责任：
      <p>
        （1）中国知链的系统停机维护或升级；<br>

        （2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；<br>

        （3）您的手机和通信线路出现故障的；<br>

        （4）因您操作不当或通过非经我们授权或认可的方式使用中国知链服务的；<br>

        （5）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统兼容性或手机软硬件故障、通讯故障、第三方服务瑕疵或政府行为等原因。<br>

        （6）尽管有前条款约定，中国知链将采取合理行动积极促使服务恢复正常。<br>
      </p>

      7.2 责任限制<br>

      中国知链可能同时为您及您的（交易）对手方提供服务，您同意对中国知链可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张中国知链在提供服务时存在法律上的瑕疵。<br>

      中国知链提供的各项服务均经过严格的内部功能测试，因用户使用的场景不同，易受网络环境等客观条件的影响，不能保证用户在使用服务时不遇到异常情况，如您在使用过程中遇到异常情况，请重新操作，或者联系客服进行申诉。<br>

    </div>
  </div>
</template>

<script>
export default {
  name: 'agreement',

  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.ageement {
  padding: 48px 64px;
  line-height: 2em;
  h3 {
    font-size: 24px;
    text-align: center;
  }
  .subTime {
    color: rgba(0, 18, 22, 0.65);
    margin: 16px 0 32px;
    text-align: center;
  }
  h4 {
    margin-top: 16px;
  }
  div {
        margin-top:8px;
    p {
      padding-left: 16px;
    }
  }
}
</style>
